import React from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'
import zip from 'lodash/zip'

import Layout from '../components/layout'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'
import Youtube from '../components/shared/Youtube'
import Legal from '../components/shared/Legal'

export default class ProteoHaulierEnterprise extends React.Component {
  constructor(props) {
    super(props)
    this.state = { input: '' }
  }
  render() {
    const {
      solutionsTitle,
      solutions,
      benefitsText,
      benefitsTitle,
      whyProteoText,
      whyProteoTitle,
      benefitsImage,
      title,
      image,
    } = this.props.data.page
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <div className="bg-dark text-white">
          {' '}
          <Container className="py-3 ">
            {/* FORM */}
            <Row className="my-5 justify-content-center">
              <Col md="7" className="d-flex flex-column justify-content-end">
                <h2 className="mb-3 text-gold">{title}</h2>
                <Row>
                  <Col md="8" lg="6">
                    {' '}
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      style={{ height: '60px' }}
                      onChange={e => {
                        this.setState({ input: e.target.value })
                      }}
                      value={this.state.input}
                      className="w-100 mt-md-3 d-flex text-center align-middle mb-4 white-btn-gradient"
                    />
                    <div>
                      <Button
                        style={{ height: '60px', lineHeight: 1 }}
                        size="lg"
                        onClick={e => {
                          e.preventDefault()
                          navigate('/contact', {
                            state: {
                              selected: 'Contact Us',
                              email: this.state.input,
                            },
                          })
                        }}
                        className="w-100 text-white py-3  text-center   primary-btn-gradient"
                      >
                        Get Started
                      </Button>
                    </div>
                    <Legal dark className="text-center pb-4 pb-md-0" />
                  </Col>
                </Row>
              </Col>

              <Col md={{ size: 5 }}>
                <img
                  alt={image.description}
                  src={image.file.url}
                  title={image.title}
                  className="img-fluid mb-3 border-40"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="  py-5">
          <Container>
            <Row>
              <Col
                md="12"
                className="d-flex  flex-column justify-content-center"
              >
                <div
                  style={{ fontSize: '1.8em' }}
                  className="text-primary text-center mb-4"
                  dangerouslySetInnerHTML={{ __html: whyProteoTitle }}
                />

                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{ __html: whyProteoText.g.html }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className=" text-white bg-dark py-5">
          <Container>
            <Row>
              <Col
                md="12"
                className="d-flex  flex-column justify-content-center"
              >
                <div
                  style={{ fontSize: '1.8em' }}
                  className="text-primary text-center mb-4"
                  dangerouslySetInnerHTML={{ __html: solutionsTitle }}
                />

                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{ __html: solutions.g.html }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className=" py-5">
          <Container>
            <h2 className="text-primary text-center pb-4">{benefitsTitle}</h2>
            <Row className="align-items-center">
              <Col
                xs={{ order: 2 }}
                className="text-justify"
                lg={{ size: 6, order: 1 }}
                dangerouslySetInnerHTML={{ __html: benefitsText.g.html }}
              />
              <Col xs={{ order: 1 }} lg={{ size: 5, offset: 1, order: 2 }}>
                <img
                  className="d-block w-100 mb-3 mb-lg-0"
                  src={benefitsImage.file.url}
                  alt={benefitsImage.description}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-1" />
        <Icons />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    seo: contentfulProteoInsightsPage {
      title: pageTitle
      description
      keywords
    }
    page: contentfulProteoInsightsPage {
      id
      title: headerTitle
      image: headerImage {
        title
        description
        file {
          url
        }
      }
      solutionsTitle
      solutions {
        g: childMarkdownRemark {
          html
        }
      }
      benefitsTitle
      benefitsText {
        g: childMarkdownRemark {
          html
        }
      }
      whyProteoTitle
      whyProteoText {
        g: childMarkdownRemark {
          html
        }
      }
      benefitsImage {
        title
        description
        file {
          url
        }
      }
      featuresTitle
      featuresText {
        g: childMarkdownRemark {
          html
        }
      }
    }
  }
`
